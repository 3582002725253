import { auth } from '../shared/firebase.js';
import { signInWithCustomToken } from 'firebase/auth';
const delay = (ms) => new Promise(res => setTimeout(res, ms));

// Function to fetch Firebase token using session ID
async function fetchFirebaseToken() {
    try {
        const response = await fetch('https://api.thisisnoahevans.com/get-token', {
            method: 'GET',
            credentials: 'include'  // to send the cookie along with the request
        });
        if (response.ok) {
            const data = await response.json();
            console.log(`GOT A FIREBASE TOKEN: ${data.firebaseToken}`);
            return data.firebaseToken;
        } else {
            throw new Error('Failed to fetch Firebase token');
        }
    } catch (error) {
        console.error('Error fetching Firebase token:', error);
        throw error;
    }
}

// On DOM load, initiate the token fetch and authentication
window.addEventListener('DOMContentLoaded', async () => {
    console.log('Attempting to sign in with session token');
    await delay(1000);
    try {
        const firebaseToken = await fetchFirebaseToken();
        console.log('Signing in...')
        await signInWithCustomToken(auth, firebaseToken)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(`Signed in as ${user.displayName}!`);
                document.getElementById('userDetails').style.display = 'block';
                document.getElementById('userName').innerText = `Welcome, ${user.displayName}`;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error('Authentication error:', errorCode, errorMessage);
                redirectToAuth('https://auth.thisisnoahevans.com?redirect=https://app1.thisisnoahevans.com');
            });

    } catch (error) {
        console.error('Authentication error:', error);
        redirectToAuth('https://auth.thisisnoahevans.com?redirect=https://app1.thisisnoahevans.com');
    }
});

function redirectToAuth(redirectUrl) {
    window.location.href = redirectUrl;
}
